import {  httpCore } from './http.js'

export default {
    async refreshToken(token) {
        return httpCore.post(`/session/refresh/?refreshToken=${token}`);
    },
    async locations() {
        return httpCore.get(`/locations`);
    },
    async update(key, dto) {
        return httpCore.put(`/config/${key}`, dto);
    },
    async get(key) {
        return httpCore.get(`/config/${key}`);
    },
    async config() {
        return httpCore.get(`/config`);
    },
}