import Swal from "sweetalert2";
import { toast } from 'vue3-toastify';
import moment from 'moment-timezone';
//import {store} from '../state/store'
export default {
    toast_options(){
        return { 
            //theme: 'dark',
            autoClose: 5000,
            clearOnUrlChange : false,
            position: toast.POSITION.BOTTOM_RIGHT,
        }
    },
    
    async warningHttp(e) {
        if (e.fromApi) {
            var title = e.http_body.message;
            this.warning(title,'');
        } else {
            this.toast_error('Problemas em sua conexão, tente novamente.');
        }
    },

    async toastHttp(e) {
        if (e.fromApi) {
            this.toast_warning(e);
        } else {
            this.toast_error('Problemas em sua conexão, tente novamente.');
        }
    },
    
    async toast_warning(title) {
        var options = this.toast_options();
        options.type = 'warning';

        if(title.fromApi == true && title.http_body){
            title = title.http_body.message;
        }

        await toast(title,   options); // ToastOptions
    },
    async toast_info(title) {
        var options = this.toast_options();
        options.type = 'info';
        if(title.fromApi == true && title.http_body){
            title = title.http_body.message;
        }
        await toast(title,   options); // ToastOptions
    },
    async toast_error(title) {
        var options = this.toast_options();
        options.type = 'error';
        if(title.fromApi == true && title.http_body){
            title = title.http_body.message;
        }
        await toast(title,   options); // ToastOptions
    },
    async toast_success(title) {
        var options = this.toast_options();
        options.type = 'success';
        await toast(title,   options); // ToastOptions
    },

    closeFreeze(dialog) {
        dialog.close();
    },
    freezeLoading(title, subtitle = `Por favor aguarde...`) {
        var dialog = Swal.fire({
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            title: title,
            html: ` <p class="fs-5">${subtitle}</p>
                    <div class="progress"><div class="progress-bar bg-info progress-bar-striped progress-bar-animated" style="width: 100%;"></div></div>`,
        });
        return dialog; 
    },
    info(title, subtitle = "", timer = 0){
            Swal.fire({
                icon: 'info',
                customClass: {
                    okButton: "btn btn-success",
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                title: title,
                html : `<p>${subtitle}</p>`,
                timer: timer,
                timerProgressBar: true,
            });
    },
    async success(title, subtitle = "", timer = 0){
            await Swal.fire({
                icon: 'success',
                title: title,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-info",
                    cancelButton: "btn btn-danger"
                },
                html : `<p>${subtitle}</p>`,
                timer: timer,
                timerProgressBar: true,
            });
    },
    warning(title, error){
        if (error && error.http_body) {
            var code = '';
            if (error.http_body.type != 'TESTE') {
                code = `<b>Warning Code:</b> ${error.http_body.type}<br>`;
            }
            var time = moment(new Date()).format("DD/MM/yyyy HH:mm");
            Swal.fire({
                icon: 'warning',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                title: title,
                html : `
                        <p class="alert-warning alert"> <em class="icon ni ni-shield-alert-fill  "></em> ${error.http_body.message}</p>
                        <div style="font-size: 12px; text-align: left;" class="text-muted">
                            ${code}
                            <b>Log:</b> ${error.http_body.trace} -   ${time}<br>
                        </div>`,
                timerProgressBar: true,
            });
        } else {
            Swal.fire({
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                icon: 'warning',
                title: title,
                html : error || '',
                timerProgressBar: true,
            });
        }
    },
    async error(title, error){
        console.error(`Messages error: `, error);
        



        if (error && error.http_body) {

            if (error.fromApi) {
                this.warning(title, error); 
                return;
            }

            var code = '';
            if (error.http_body.type != 'TESTE') {
                code = `<b>Error Code:</b> ${error.http_body.type}<br>`;
            }
            var time = moment(new Date()).format("DD/MM/yyyy HH:mm");
            await Swal.fire({
                icon: 'error',
                title: title,
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                html : `
                        <p class="alert-danger alert">${error.http_body.message}</p>
                        <div style="font-size: 12px; text-align: left;" class="text-muted">
                            ${code}
                            <b>Log:</b> ${error.http_body.trace} -   ${time}<br>
                        </div>`,
                timerProgressBar: true,
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: title,
                html : error || '',
                timerProgressBar: true,
            });
        }
    },
    dialog(title, text, buttonConfirm, buttonCancel, icon, confirmButton, cancelButton,   action) {
             const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: confirmButton ? confirmButton : 'btn btn-success ms-2',
                    cancelButton:  cancelButton ?  cancelButton : 'btn btn-outline-dark'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title:  title,
                text: text ? text : "You won't be able to revert this!",
                icon: icon ? icon : 'question',
                showCancelButton: true,
                confirmButtonText: buttonConfirm ? buttonConfirm : 'Yes, delete it!',
                cancelButtonText:  buttonCancel ? buttonCancel : 'Cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action(true);
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    /*
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                    )*/
                }
            })   
    }

}