export const state = {
    lastRefresh     : null,
    logged          : false,
    jwt             : null,
    userName        : null,
    userId          : null,
    sessionId       : null,
    role            : null,
    permissions     : [],
    refreshToken    : null,
};

export const mutations = {
    setLogged(state, { logged }){
        state.logged = logged;
    },
    setLastRefresh(state){
        state.lastRefresh = new Date();
    },
    setLogout(state){
        state.jwt = null;
        state.logged = false;
        state.sessionId = null;
        state.refreshToken = null; 
        localStorage.removeItem('cache_config_pl');
        localStorage.removeItem('pl-login');
    },
    setLogin(state, { jwt, refreshToken, sessionId, permissions, role }){
        state.jwt = jwt;
        state.logged = true;
        state.permissions = permissions
        state.sessionId = sessionId;
        state.role = role;
        
        state.refreshToken = refreshToken;
        //getUserInfo();
        //persistSession();
    },

    setUserName(state, userName){
        state.userName = userName;
    },
    setUserId(state, userId){
        state.userId = userId;
    },
    setRefreshToken(state, refreshToken){
        state.refreshToken = refreshToken;
    },
    setSessionId(state, sessionId){
        state.sessionId = sessionId;
    }
};

