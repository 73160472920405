import store from '../state/store'
import Messages from '../mixings/Messages'
import UtilMixing from '../mixings/UtilMixing'
let refreshTokenPromise;

const mountHttpError = (httpException) => {
    let error = {
        fromApi   : false,
        http_code : 500,
        http_body : {
            error : true,
            trace : 'GEN0001 - 00',
            message : 'Problema com conexão, tente novamente.',
            type :  'Erro'
        }
    }
    console.error(httpException);
    if(httpException.response) {
        error = {
            fromApi  : true,
            http_code : httpException.response.status,
            http_body : {
                error : true,
                trace : ((httpException.response.data.trace ? httpException.response.data.trace : 'GEN0001')),
                message : ((httpException.response.data.message ? httpException.response.data.message : 'Try again.')),
                type :  ((httpException.response.data.type ? httpException.response.data.type : 'GENERIC')),
            }
        }
    }
    console.error(`Final Error: `, error);
    return error;
};

const setup = (http) => {
    http.interceptors.request.use (
      function (config) {
        if(store.state.session.logged){
            let jwt  = store.state.session.jwt;
            let idSessao = store.state.session.sessionId;
            config.headers["Authorization"] = "Bearer "+jwt;
            config.headers["trp-session"] = idSessao;
            config.headers["trp-ip"] = store.state.session.ip; 
            config.headers["trp-os"] =  UtilMixing.getOS();

            config.headers["trp-geoCity"] =  store.state.session.ipData.city;
            config.headers["trp-geoState"] =  store.state.session.ipData.state;

            config.headers["trp-device"] =  UtilMixing.getDevice();
            config.headers["trp-browser"] =  UtilMixing.getBrowser();
            config.headers["trp-version"] = process.env.VUE_APP_VERSION;
        }
        return config;
      },
      function (error) {
        console.dir('error', error);
        return Promise.reject(error);
      }
    );




    http.interceptors.response.use(r => r, async error => {
        if(error.config.abort){
            window.location.href =  '#/public/login?Login';
            throw new Error('Ops... sua sessão expirou, faça login novamente.');
        }

        if (error.config && error.response && error.response.status === 401 && error.config.abort != true) {
            if (!refreshTokenPromise) {
                if(store.state.session.env == 'local')
                    Messages.toast_info(`Refresh Sessão: ${store.state.session.sessionId}`);


                
                let actualRefresh = store.state.session.refreshToken;
                refreshTokenPromise = http.post(`/session/refresh/?refreshToken=${actualRefresh}`)
                    .then(({ data }) => {
                        let jwt = data.jwt;
                        let refreshToken = data.refreshToken;
                        let sessionId = store.state.session.sessionId
                        
                        store.commit('execRefresh', {jwt, refreshToken, sessionId});
                        refreshTokenPromise = null
                        return jwt;
                    }).catch(function(){
                        store.commit('setLogout'); 
                        return null;
                    });
            }
            return refreshTokenPromise.then(token => {
                if (token == null) {
                                       
                    error.config.abort = true;
                    return http.request(error.config);
                } else { 
                    error.config.headers['X-CSRF-TOKEN'] = token
                    return http.request(error.config)
                }
            })
        }
        return Promise.reject(mountHttpError(error))
    })

}


export default setup;