import axios from 'axios';
import httpRegister from './httpRegister';

 var baseUrl = (process.env.VUE_APP_ENV == 'local' ? 'http://localhost:3000' : window.location.origin)



const httpCore = axios.create ({
  baseURL: `${baseUrl}/api`,
  timeout: 1000 * 60 * 2, //2 minutes
  headers: {'Content-Type': 'application/json'},
});

httpRegister(httpCore, false);

export {httpCore};